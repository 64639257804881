import { BrlValue } from "~~/stores/inspection.store"
import type { RiskProfile } from "~~/stores/riskSettings.store"
import { GeneralCondition } from "~~/stores/asset.store"
import type { StatusDescription } from "~~/stores/status.store"

export function conditionColor(condition: GeneralCondition) {
  if (condition === GeneralCondition.GOOD) {
    return "success"
  }
  if (condition === GeneralCondition.DECENT) {
    return "warning"
  }
  if (condition === GeneralCondition.INSUFFICIENT) {
    return "yellow-darken-4"
  }
  if (condition === GeneralCondition.BAD) {
    return "error"
  }

  return "grey"
}

export function riskProfileColor(riskProfile?: RiskProfile) {
  if (riskProfile === "LOW") {
    return "success"
  }
  if (riskProfile === "MEDIUM") {
    return "orange"
  }
  if (riskProfile === "HIGH") {
    return "red"
  }
  if (riskProfile === "VERY_HIGH") {
    return "error"
  }

  return "grey"
}

export function userRoleColor(role: string) {
  if (role === "SUPER_ADMIN") {
    return "admin"
  }
  if (role === "USER") {
    return "primary"
  }
  if (role === "SUPPLIER_MANAGER") {
    return "orange"
  }
  if (role === "SUPPLIER_MECHANIC") {
    return "orange"
  }
  if (role === "SUPPLIER_PLANNER") {
    return "orange"
  }

  return "grey"
}

export function brlValueColor(value: BrlValue) {
  if (value === BrlValue.GOOD) {
    return "success"
  }
  if (value === BrlValue.DECENT) {
    return "warning"
  }
  if (value === BrlValue.BAD) {
    return "error"
  }
  if (value === BrlValue.REPLACED) {
    return "secondary"
  }
  if (value === BrlValue.NA) {
    return "primary"
  }

  return "grey"
}

export function riskScoreColor(value: "low" | "medium" | "high" | "critical") {
  if (value === "low") {
    return "amber"
  }
  if (value === "medium") {
    return "amber-darken-2"
  }
  if (value === "high") {
    return "deep-orange-lighten-2"
  }
  if (value === "critical") {
    return "red"
  }

  return "grey"
}

const statusColor = {
  NEW: "#FF5630",
  QUOTATION: "#FFC400",
  QUOTE_AWAIT_APPROVAL: "#FFC400",
  QUOTE_ACCEPTED: "#FFC400",
  QUOTE_REJECTED: "#FFC400",
  IN_ORDER: "#FFC400",
  PROCESSING: "#FFC400",
  DID_NOT_PROCESS: "#FF991F",
  PROCESSED: "#36B37E",
  AWAIT_ACCEPTANCE: "#6554C0",
  ACCEPTED: "#6554C0",
  REJECTED: "#6554C0",
  INVOICED: "#6554C0",
  DONE: "#4C9AFF",
}

export function getTicketStatusColor(ticketStatus: StatusDescription) {
  const color = statusColor[ticketStatus]
  return color || "#000000"
}

export const getContractStatusColor = (status: ContractStatus) => {
  switch (status) {
    case ContractStatus.APPROVED:
      return "success"

    case ContractStatus.AWAITING_START_DATE:
    case ContractStatus.AWAITING_APPROVAL:
    case ContractStatus.PARTIALLY_APPROVED:
      return "warning"

    case ContractStatus.EXPIRED:
    case ContractStatus.NO_ACTIVE_PRICE_LISTS:
    case ContractStatus.MANUALLY_ENDED:
      return "grey"

    case ContractStatus.DENIED:
      return "error"
  }
}

export const getPriceListStatusColor = (status: PriceListStatus) => {
  switch (status) {
    case PriceListStatus.AWAITING_START_DATE:
    case PriceListStatus.AWAITING_APPROVAL:
      return "warning"

    case PriceListStatus.EXPIRED:
    case PriceListStatus.MANUALLY_ENDED:
      return "grey"

    case PriceListStatus.DENIED:
      return "error"

    case PriceListStatus.APPROVED:
      return "success"
  }
}
